
import VueCookies from 'vue-cookies';
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        report: []
    },
    getters:{
        inOutState: state => state,
    },
    mutations:{
        setReport(state,value) {
            state.report = value
        }
    },
    actions:{
        async getInOutRep({commit},values){
            const post = new FormData();
            post.append('type','getInOutRep');
            post.append('data[sdate]',values.sdate);
            post.append('data[edate]',values.edate);
            post.append('auth',VueCookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/index.php',post
            ).catch(err =>{
                console.log("errrrrrrrrrr",err);
            })
            console.log(response.data);
            
            if(response && response.data){
                commit('setReport',response.data.results.data)
            }
            
        }
    }
}