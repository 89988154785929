import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Loading from '../views/Loading.vue'

Vue.use(VueRouter)

/****
version = 1 // ecar
version = 2 //
version = 3 // pos retail
version = 4 // crm
version = 5 // invoicelist
version = 6 // POS
 ****/

// const version = 1;

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Loading',
    component: Loading
  }, 
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: Home
  // },
  {
    path: '/home',
    name: 'Home',
    meta: { transition: 'slide-left' },
    component: () => import( '@/views/CustomerList.vue')
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/clientList.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/POS-RETAIL.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/POS.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/ORDER.vue')
  // },
  {
    path: '/invoicelist',
    name: 'InvoiceList',
    component: () => import( '@/views/invoicesList.vue')
  },
  {
    path: '/orders',
    name: 'ORDER',
    meta: { transition: 'slide-left' },
    component: () => import( '@/views/ORDER.vue') 
  },
  {
    path: '/quotations',
    name: 'quotations',
    component: () => import( '@/views/quotations.vue')
  },
  {
    path: '/updateme',
    name: 'updateme',
    component: () => import( '@/views/updateme.vue')
  },
  {
    path: '/reports/vatreports',
    name: 'vatreports',
    component: () => import( '@/views/vatreports.vue')
  },
  {
    path: '/quotations/n/1',
    name: 'viewquotations',
    component: () => import( '@/views/quotations.vue')
  },
  {
    path: '/quotations/i/2',
    name: 'viewquotations1',
    component: () => import( '@/views/quotations.vue')
  },
  {
    path: '/quotations/c/3',
    name: 'viewquotations2',
    component: () => import( '@/views/quotations.vue')
  },
  {
    path: '/quotations/:status',
    name: 'viewquotations3',
    component: () => import( '@/views/quotations.vue')
  },
  {
    path: '/quotations/:status',
    name: 'viewquotations4',
    component: () => import( '@/views/quotations.vue')
  },
  {
    path: '/view-quot/:id',
    name: 'viewQuotation',
    component: () => import( '@/views/viewQuotation.vue')
  },
  {
    path: '/clientList',
    name: 'clientList',
    component: () => import( '@/views/clientList.vue')
  },
  {
    path: '/client-statement/:id',
    name: 'clientStatement',
    component: () => import( '@/views/clientStatement.vue')
  },
  {
    path: '/view-invoice/:id',
    name: 'viewInvoice',
    component: () => import( '@/views/viewInvoice.vue')
  },
  {
    path: '/customers',
    name: 'CustomerList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomerList.vue')
  },
  {
    path: '/reports/in-out-report',
    name: 'IncomeOutComeReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inoutreport" */ '../views/inoutreport.vue')
  },
  {
    path: '/Licenses',
    name: 'License-Generator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LicenseGen.vue')
  },
  {
    path: '/sms',
    name: 'SMS-Generator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/smsTemplates.vue')
  },
  {
    path: '/viewcustomer/:id',
    name: 'view-order',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/viewOrder.vue')
  },
  {
    path: '/salary-reports',
    name: 'SalaryReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/salary-report.vue')
  },
  {
    path: '/car-report',
    name: 'car-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/car-report.vue')
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  // {
  //   path: '/printQuot/:id',
  //   name: 'printQuotation',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/printQuotation.vue')
  // },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: { transition: 'slide-left' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/view-card/:cardid',
    name: 'ViewCard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewCard.vue')
  },
  {
    path: '/print-card/:cardid',
    name: 'printCard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/printCard.vue')
  },
  {
    path: '/print-invoice/:cardid',
    name: 'printInvoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/printInvoice.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Logout.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/reports.vue')
  },
  {
    path: '/reports/cards-reports',
    name: 'cards-reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/cards-reports.vue')
  },
  {
    path: '/reports/percent-reports',
    name: 'percent-reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/percent-reports.vue')
  },
  {
    path: '/reports/sales-reports',
    name: 'sales-reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/sales-reports.vue')
  },
  {
    path: '/reports/acbalance-report',
    name: 'acbalance-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/acbalance-report.vue')
  },
  {
    path: '/reports/bnl-report',
    name: 'bnl-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/bnl-report.vue')
  },
  {
    path: '/reports/return-reports',
    name: 'return-reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/return-reports.vue')
  },
  {
    path: '/reports/vat-reports',
    name: 'vat-reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/vat-reports.vue')
  },
  {
    path: '/reports/income-report',
    name: 'income-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( '../views/income-report.vue')
    component: () => import( '../views/inoutreport.vue')
  },
  {
    path: '/reports/statment-report',
    name: 'statment-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/statment-report.vue')
  },
  {
    path: '/reports/balance-report',
    name: 'balance-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/balance-report.vue')
  },
  {
    path: '/reports/daily-reports',
    name: 'daily-reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/daily-reports.vue')
  },
  {
    path: '/finance',
    name: 'Finance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Finance.vue')
  },
  {
    path: '/finance/inreceipt',
    name: 'InReceipt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/InReceipt.vue')
  },
  {
    path: '/finance/outreceipt',
    name: 'OutReceipt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/OutReceipt.vue')
  },
  {
    path: '/finance/jvs',
    name: 'Jornal-Voucher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/JournalVoucher.vue')
  },
  {
    path: '/finance/fient',
    name: 'fJornal-Voucher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/fJournalVoucher.vue')
  },
  {
    path: '/inventory/vendors',
    name: 'vendors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/vendors.vue')
  },
  {
    path: '/inventory/purchase',
    name: 'Purchase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Purchase.vue')
  },
  {
    path: '/SAMPOS',
    name: 'POS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/POS.vue')
  },
  {
    path: '/POS-RETAIL',
    name: 'POS-RETAIL',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/POS-RETAIL.vue')
  },
  {
    path: '/marketing',
    name: 'MARKETING',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/marketing.vue')
  },
  {
    path: '/settings',
    name: 'SETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/settings.vue')
  },
  {
    path: '/settings/system-settings',
    name: 'SYSTEM SETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/system-settings.vue')
  },
  {
    path: '/emps/users-settings',
    name: 'USERS SETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/users-settings.vue')
  },
  {
    path: '/settings/users-settings',
    name: 'USERSSETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/users-settings.vue')
  },
  {
    path: '/settings/permissions-settings',
    name: 'PERMISSIONS SETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/permissions-settings.vue')
  },
  {
    path: '/emps/salaries',
    name: 'SALARIES',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/salaries.vue')
  },
  {
    path: '/emps/gozi-report',
    name: 'gozi-report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/gozi-report.vue')
  },
  {
    path: '/expenses/recurring',
    name: 'expenses',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/expenses.vue')
  },
  {
    path: '/expenses/management',
    name: 'management',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/exp-management.vue')
  },
  {
    path: '/inventory/products',
    name: 'Products',
    component: () => import( '@/views/settings-products.vue')
  },
  {
    path: '/inventory/products-cats',
    name: 'products-cats',
    component: () => import( '@/views/products-cats.vue')
  },
  {
    path: '/inventory/best-seller',
    name: 'bestseller',
    component: () => import( '@/views/best-seller.vue')
  },
  {
    path: '/inventory/lowest-selling',
    name: 'lowestselling',
    component: () => import( '@/views/lowest-selling.vue')
  },
  {
    path: '/inventory/finished',
    name: 'finished',
    component: () => import( '@/views/finished.vue')
  },
  {
    path: '/view-purchase/:id',
    name: 'view-purchase',
    component: () => import( '@/views/viewPurchase.vue')
  },
  {
    path: '/emp/list',
    name: 'employee-list',
    component: () => import( '@/views/employees.vue')
  },
  {
    path: '/emps/view/:id',
    name: 'employeeview',
    component: () => import( '@/views/view-employees.vue')
  },
  {
    path: '/emps/salary-table',
    name: 'salarytable',
    component: () => import( '@/views/salary-table.vue')
  },
  {
    path: '/updateuser/:id',
    name: 'employee-view',
    component: () => import( '@/views/update-user.vue')
  },
  {
    path: '/expenses/summary',
    name: 'expenses-module',
    component: () => import( '@/views/expenses-module.vue')
  },
  {
    path: '/expenses/general',
    name: 'expenses-module1',
    component: () => import( '@/views/expenses-general.vue')
  },
  {
    path: '/finance/coa',
    name: 'chart-of-account',
    component: () => import( '@/views/chart-of-account.vue')
  },
  {
    path: '/finance/definition',
    name: 'finance-definitions',
    component: () => import( '@/views/finance-definitions.vue')
  },
  {
    path: '/finance/paytypes',
    name: 'finance-paytypes',
    component: () => import( '@/views/finance-paytypes.vue')
  },
  {
    path: '/finance/fixed-assets',
    name: 'fixed-assets',
    component: () => import( '@/views/fixed-assets.vue')
  },
  {
    path: '/inventory/itemtrans',
    name: 'itemtrans',
    component: () => import( '@/views/itemtrans.vue')
  },
  {
    path: '/vendors/vendor-index/',
    name: 'vendor control panel',
    component: () => import('@/views/vendor-index.vue')
  },
  {
    path: '/vendors/products/',
    name: 'vendor products',
    component: () => import('@/views/vendors-products.vue')
  },
  {
    path: '/vendors/payment-requests/',
    name: 'vendor payment-requests',
    component: () => import('@/views/vendor-payments.vue')
  },
  {
    path: '/vendors/requests/',
    name: 'vendor send-requests',
    component: () => import('@/views/vendor-requests.vue')
  },
  {
    path: '/vendor-sents/',
    name: 'vendor-sents',
    component: () => import('@/views/vendor-sents.vue')
  },
  {
    path: '/vendor-prequests/',
    name: 'vendor-psent',
    component: () => import('@/views/vendor-psent.vue')
  },
  {
    path: '/billClients',
    name: 'billClients',
    component: () => import('@/views/showClients.vue')
  },
  {
    path: '/customer-suppport',
    name: 'CustomerSupport',
    component: () => import('@/views/customerSupport.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 page',
    component: () => import('@/views/404.vue')
  }
]


// if(version == 1){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: Home
//     })
// }
// if(version == 2){
//   routes.push(
//     {
//       path: '/home1',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: Home
//     })
// }
// if(version == 3){
//   routes.push(
//     {
//       path: '/home1',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/POS-RETAIL.vue')
//     })
// }
// if(version == 4){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/CustomerList.vue')
//     })
// }
// if(version == 5){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/invoicesList.vue')
//     })
// }
// else if(version == 6){
//   routes.push(
//     {
//       path: '/home',
//       name: 'Home',
//       meta: { transition: 'slide-left' },
//       component: () => import( '@/views/POS.vue')
//     })
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
